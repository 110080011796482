import { useRouter } from "next/router";
import ErrorPage from "next/error";
import PostBody from "../components/post-body";
import MoreStories from "../components/more-stories";
import Header from "../components/Header";
import PostHeader from "../components/post-header";
import SectionSeparator from "../components/section-separator";
import Layout from "../components/layout";
import Footer from "../components/Footer";
import Cta from "../components/Cta";
import {
  getHomepage,
  getResources,
  getAllPostsWithSlug,
  getPostAndMorePosts,
  getPageBySlug,
  getAllPostsForHome,
  getCustomOptions,
} from "../lib/api";
import PostTitle from "../components/post-title";
import Head from "next/head";
import { CMS_NAME } from "../lib/constants";
import Tags from "../components/tags";
import Blocks from "../components/Blocks";

export default function Homepage({
  page,
  resources,
  footerData,
  ctaData,
  preview,
}) {
  const router = useRouter();
  if (!router.isFallback && !page?.slug) {
    return (
      <Layout preview={preview}>
        <div>
          <Error />
        </div>
        {footerData && <Footer data={footerData} />}
      </Layout>
    );
  }

  return (
    <Layout preview={preview} seo={page.seo}>
      <div>
        <Header />
        {router.isFallback ? (
          <p>Loading…</p>
        ) : (
          <Blocks blocks={page.editorBlocks} posts={resources} footerData={footerData} />
        )}
        {ctaData && (
          <Cta
            title={ctaData.title}
            subtitle={ctaData.subtitle}
            icon={ctaData.icon}
            button={ctaData.button}
          />
        )}
      </div>
      {footerData && <Footer data={footerData} />}
    </Layout>
  );
}

export async function getStaticProps({ params, preview = false, previewData }) {
  const page = await getHomepage(preview);
  const { nodes: resources } = await getResources(null, preview, previewData);
  const { footer, cta } = await getCustomOptions(preview);
  return {
    props: {
      preview: preview,
      resources: resources,
      footerData: footer,
      page: page,
      ctaData: cta,
    },
    revalidate: 10,
  };
}
